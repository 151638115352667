import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Icon, Spin } from "antd";
import { Companies, HireMe, Jobs } from "../src/components/layouts/partials";
import {
    followCompany,
    generateStateUpdate,
    getAllCategory,
    getAllCompanies,
    getAllJobs,
    getAllUsers,
    jobFilterInputChange, openUserDetails
} from "../src/store/actions";
import { redirect } from "../src/utils/redirect";
import { APP_GENERAL_STATE } from "../src/store/ActionTypes";
import { CompanyGrid, GetStarted } from "../src/components/common";

class Home extends Component {
    state = {
        selectedType: 'functional',
        searchValue: '',
        selectedJobType: 'popular',
        catSectionHide: false,
        childCat: false,
        selectedParentCategory: '',
        scrolledAt: 'jobSection'
    };

    constructor(props) {
        super(props);
        this.catSectionToggle = this.catSectionToggle.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.selectCategoryType = this.selectCategoryType.bind(this);
        this.myRef = React.createRef();
        this.myRefJobList = React.createRef();

    }

    static async getInitialProps(ctx) {
        await ctx.store.dispatch(getAllCategory(ctx, 'all', true));
        await ctx.store.dispatch(getAllUsers({ limit: 9 }, false, true));
        await ctx.store.dispatch(getAllJobs({ job_type: 'popular', limit: 9 }, ctx, true));
        await ctx.store.dispatch(getAllCompanies({ limit: 9 }, ctx, false, true));

        return {
            title: 'Jobs in Nepal - Job Vacancies in Nepal | Post free Vacancy in Nepal | Rojgari Sanjal',
            showFooter: true,
            meta_desc: 'Rojgari Sanjal is a free job portal in Nepal. we offer latest jobs in Nepal, See job vacancies in nepal, find employee and career opportunities in Nepal  for free.',
            keywords: 'rojgari sanjal , jobs in Nepal, job site in Nepal, job vacancies in Nepal, online job in Nepal, part time job in Nepal, freelancer jobs in Nepal,  job vacancy in Nepal, free job site in Nepal, recent job vacancies in Nepal, job opportunities in Nepal, free job portal  in Nepal,find employee in Nepal, how to get employee in Nepal, top  jobs in Nepal, best jobs in Nepal, job search in Nepal, job search.'
        }
    }

    catSectionToggle() {
        this.setState({ catSectionHide: !this.state.catSectionHide });
    }

    selectCategoryType(type) {
        this.setState({ selectedType: type })
    }

    selectCategory(cat) {
        if (cat === this.state.selectedParentCategory) {
            this.setState({ selectedParentCategory: '' })
        } else {
            this.setState({ selectedParentCategory: cat })
        }
    }

    getCategoryByType() {
        const { category } = this.props;
        const type = this.state.selectedType === 'functional' ? 1 : 0;

        return category.data.filter((item) => {
            return item.type === type;
        });
    }

    getCategoryCount(countType = 'functional') {
        const { category, job } = this.props;
        const type = countType === 'functional' ? 1 : 0;

        let items = category.data.filter((item) => {
            return item.type === type;
        });
        return items.length;
    }

    getAndSetJobType(type) {
        this.setState({ selectedJobType: type });
        this.props.getAllJobs({ limit: 9, job_type: type }, {}, true);
    }


    render() {
        const { user, job, jobFilterInputChange, generateStateUpdate, company } = this.props;
        const { homePageUserList } = user;
        let { homePageCompanyList } = company;
        let $this = this;
        return (<div>
            <div className="tr-banner section-before bg-image">
                <div className="container">
                    <div className="banner-content text-center">
                        <h1>The Easiest Way to Get Your New Job</h1>
                        <h2>We offer many jobs vacancies right now</h2>

                        <div className="banner-form large_screen_search">
                            <form action="#">
                                <input type="text" onChange={(e) => {
                                    this.props.jobFilterInputChange({
                                        props: 'search',
                                        value: e.target.value,
                                        searchNow: false
                                    });
                                }} className="form-control" placeholder="Job Keyword" />
                                <div className="dropdown tr-change-dropdown">
                                    <input type="text" onChange={(e) => {
                                        this.props.jobFilterInputChange({
                                            props: 'address',
                                            value: e.target.value,
                                            searchNow: false
                                        });
                                    }} className="form-control" placeholder="Location" />
                                </div>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    if (job.filter.address || job.filter.search)
                                        redirect('/vacancies');
                                }} type="submit" className="btn btn-primary" value="Search">
                                    Search
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cat_job_section" ref={this.myRefJobList}>
                <div className={"category_lister " + (this.state.catSectionHide ? 'hide' : '')}>

                    <div ref={this.myRef} className="category-scroller">
                        <div onClick={this.catSectionToggle} className="cat_settings" title={'Hide section'}>
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                            }}>
                                <Icon type="setting" />
                            </a>
                        </div>
                        <div className="categories_list">
                            <h4>Jobs by Category</h4>
                            <div className="category_switcher">
                                <a href="" onClick={(e) => {
                                    e.preventDefault();
                                    this.selectCategoryType('functional');
                                }}
                                    className={this.state.selectedType === 'functional' ? 'active' : ''}>Functional</a>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.selectCategoryType('industrial');
                                }} href=""
                                    className={this.state.selectedType === 'industrial' ? 'active' : ''}>Industrial</a>
                            </div>

                            <ul>
                                {this.getCategoryByType().map(function (item, key) {
                                    // return <AppCategory key={key} category={item}/>
                                    return (<li key={key}>
                                        <div className={`category-items`}>
                                            {/*<Icon type="delete"/>*/}
                                            <a onClick={(e) => {
                                                e.preventDefault();

                                            }} href="">{item.name}</a>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                $this.selectCategory(key);
                                            }} href=""
                                                className={'expander ' + ($this.state.selectedParentCategory === key ? 'open' : '')}>
                                                {item.children.length ? <Icon type="plus" /> : null}
                                            </a>
                                        </div>
                                        {item.children.length ?
                                            <ul ref={'content'}
                                                className={`child-categories ${$this.state.selectedParentCategory === key ? 'open' : ''}`}>

                                                {item.children.map(function (child, i) {
                                                    return <li key={100 + i}>
                                                        <a onClick={(e) => {
                                                            e.preventDefault();
                                                            // jobFilterInputChange({
                                                            //     props: 'category',
                                                            //     value: item.id
                                                            // });
                                                            // jobFilterInputChange({
                                                            //     props: 'sub_category',
                                                            //     value: child.id
                                                            // });

                                                            // jobFilterInputChange({
                                                            //     props: 'job_type',
                                                            //     value: 'recent'
                                                            // });
                                                            // redirect('/vacancies/[[...slug]]', {}, `/vacancies/${child.slug}-${child.id}`);
                                                        }} href="">
                                                            <span>-</span>
                                                            <span>{child.name}</span>
                                                        </a>
                                                    </li>;
                                                })}
                                            </ul> : null}
                                    </li>);
                                })}
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="hire_job_section">
                    <div id={'jobSection'} className="interest-jobs-tab">
                        <div className="filter-tab-container">
                            <div className="container">
                                <div className="filter-tab">
                                    <div className="tab-items">
                                        <a href=""
                                            className={this.state.selectedJobType === 'popular' ? 'active' : ''}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.getAndSetJobType('popular');
                                            }}>POPULAR JOBS
                                        </a>
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            this.getAndSetJobType('recent');
                                        }} href=""
                                            className={this.state.selectedJobType === 'recent' ? 'active' : ''}> RECENT
                                            JOBS
                                        </a>
                                        <a href=""
                                            className={this.state.selectedJobType === 'top' ? 'active' : ''}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.getAndSetJobType('top');
                                            }}>TOP JOBS
                                        </a>
                                    </div>
                                    <div className="search-filter">
                                        <div className="search-input">
                                            {/*<Search*/}
                                            {/*placeholder="search jobs"*/}
                                            {/*onSearch={value => {*/}
                                            {/*}}*/}
                                            {/*style={{width: 300, height: 40, marginTop: 10}}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="search-result-display">
                                <Spin spinning={job.fetchingJobList}>
                                    <Jobs data={job.homePageJobList} />
                                </Spin>
                            </div>
                        </div>
                    </div>


                    <div id={'hireMeSec'} className="interest-jobs-tab">
                        <div className="filter-tab-container">
                            <div className="container">
                                <div className="filter-tab">
                                    <div className="tab-items">
                                        <a href=""
                                            className={'active'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}>HIRE ME
                                        </a>
                                    </div>
                                    <div className="search-filter">
                                        <div className="search-input">
                                            {/*<Search*/}
                                            {/*placeholder="search jobs"*/}
                                            {/*onSearch={value => {*/}
                                            {/*}}*/}
                                            {/*style={{width: 300, height: 40, marginTop: 10}}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="search-result-display">
                                <Spin spinning={job.fetchingJobList}>
                                    <HireMe openUserDetails={this.props.openUserDetails} users={homePageUserList} />
                                </Spin>
                            </div>
                        </div>
                    </div>

                    <div id={'followCompany'} className="interest-jobs-tab">
                        <div className="filter-tab-container">
                            <div className="container">
                                <div className="filter-tab">
                                    <div className="tab-items">
                                        <a href=""
                                            className={'active'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}>FOLLOW COMPANIES
                                        </a>
                                    </div>
                                    <div className="search-filter">
                                        <div className="search-input">
                                            {/*<Search*/}
                                            {/*placeholder="search jobs"*/}
                                            {/*onSearch={value => {*/}
                                            {/*}}*/}
                                            {/*style={{width: 300, height: 40, marginTop: 10}}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="search-result-display">
                                <Spin spinning={company.fetchingCompanyList}>
                                    <div className="row">
                                        {homePageCompanyList.map((item, key) => {
                                            return <div key={key} className="col-md-6 col-lg-4">
                                                <CompanyGrid
                                                    item={item}
                                                    key={key}
                                                    ica={user.data.ica}
                                                    followCompany={this.props.followCompany} />
                                            </div>
                                        })}

                                    </div>
                                    {/*<Companies ica={user.data.ica} data={homePageCompanyList}*/}
                                    {/*followCompany={this.props.followCompany}/>*/}
                                    {/*<HireMe users={itemList}/>*/}
                                </Spin>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <Drawer
                id={'drawer_custom'}
                className={'category_drawer drawer_custom'}
                title="Jobs By Category"
                placement="right"
                closable={false}
                onClose={() => {
                    this.props.generateStateUpdate(APP_GENERAL_STATE, { props: 'showCategorySideBar', value: false });
                }}
                visible={this.props.app.showCategorySideBar}>

                <div className="cat_job_section">
                    <div style={{ display: 'block' }}
                        className={"category_lister " + (this.state.catSectionHide ? 'hide' : '')}>
                        <div className="categories_list" style={{ padding: 0 }}>
                            <div className="category_switcher">
                                <a href="" onClick={(e) => {
                                    e.preventDefault();
                                    this.selectCategoryType('functional');
                                }}
                                    className={this.state.selectedType === 'functional' ? 'active' : ''}>Functional</a>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.selectCategoryType('industrial');
                                }} href=""
                                    className={this.state.selectedType === 'industrial' ? 'active' : ''}>Industrial</a>
                            </div>

                            <ul>
                                {this.getCategoryByType().map(function (item, key) {
                                    // return <AppCategory key={key} category={item}/>
                                    return (<li key={key}>
                                        <div className={`category-items`}>
                                            {/*<Icon type="delete"/>*/}
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                // jobFilterInputChange({props: 'category', value: item.id});
                                                // generateStateUpdate(APP_GENERAL_STATE, {
                                                //     props: 'showCategorySideBar',
                                                //     value: false
                                                // });
                                                // redirect('/vacancies');
                                            }} href="">{item.name}</a>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                $this.selectCategory(key);
                                            }} href=""
                                                className={'expander ' + ($this.state.selectedParentCategory === key ? 'open' : '')}>
                                                {item.children.length ? <Icon type="plus" /> : null}
                                            </a>
                                        </div>
                                        {item.children.length ?
                                            <ul ref={'content'}
                                                className={`child-categories ${$this.state.selectedParentCategory === key ? 'open' : ''}`}>
                                                {item.children.map(function (child, i) {
                                                    return <li key={100 + i}>
                                                        <a onClick={(e) => {
                                                            e.preventDefault();
                                                            jobFilterInputChange({
                                                                props: 'category',
                                                                value: item.id
                                                            });
                                                            jobFilterInputChange({
                                                                props: 'sub_category',
                                                                value: child.id
                                                            });
                                                            generateStateUpdate(APP_GENERAL_STATE, {
                                                                props: 'showCategorySideBar',
                                                                value: false
                                                            });

                                                            jobFilterInputChange({
                                                                props: 'job_type',
                                                                value: 'recent'
                                                            });

                                                            redirect('/vacancies/[[...slug]]', {}, `/vacancies/${child.slug}`);
                                                        }} href="">
                                                            <span>-</span>
                                                            <span>{child.name}</span>
                                                        </a>
                                                    </li>;
                                                })}
                                            </ul> : null}
                                    </li>);
                                })}
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="nav-closer">
                    <a href="" onClick={(e) => {
                        e.preventDefault();
                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                            props: 'showCategorySideBar',
                            value: false
                        });
                    }}><i className={'fa fa-times'} /></a>
                </div>

            </Drawer>


            <div className="footer-sheet-app d-lg-none d-flex">
                <a href={'#jobSection'} onClick={() => {
                    this.setState({ scrolledAt: 'jobSection' })
                }} className={"nav-item " + (this.state.scrolledAt === 'jobSection' ? 'active' : '')}>
                    <Icon type="sound" />
                    <h3>Jobs</h3>
                </a>
                <a href={'#hireMeSec'} onClick={() => {
                    this.setState({ scrolledAt: 'hireMeSec' })
                }} className={"nav-item " + (this.state.scrolledAt === 'hireMeSec' ? 'active' : '')}>
                    <Icon type="idcard" />
                    <h3>Hire Me</h3>
                </a>

                <a href={'#followCompany'} onClick={() => {
                    this.setState({ scrolledAt: 'followCompany' })
                }} className={"nav-item " + (this.state.scrolledAt === 'followCompany' ? 'active' : '')}>
                    <Icon type="bank" />
                    <h3>Follow</h3>
                </a>
            </div>

            <GetStarted isAuthenticated={this.props.user.authCheck} />
        </div>
        );
    }


    trackScrolling = (e) => {

        const bottomOffset = this.state.selectedType === 'functional' ? -900 : -100;

        let $window = window;
        const sideBar = this.myRef;
        const sideBarHeight = sideBar.current.clientHeight;
        const footerOffset = document.querySelector('.footer').getBoundingClientRect();
        const sideBarOffset = sideBar.current.getBoundingClientRect();
        const listContainer = this.myRefJobList.current.getBoundingClientRect();
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > this.lastScrollTop) {
            if ((footerOffset.top - sideBarOffset.bottom) <= 50) {
                //up scroll
                sideBar.current.setAttribute('style', '');
                sideBar.current.style.position = 'absolute';
                sideBar.current.style.bottom = '0px';
                sideBar.current.style.width = '360px';
            } else if (sideBarOffset.top <= bottomOffset && sideBar.current.style.position !== 'absolute' && (listContainer.height - sideBarOffset.height) > 20) {
                sideBar.current.style.position = 'fixed';
                sideBar.current.style.top = bottomOffset + 'px';
                sideBar.current.style.width = '360px';
            }
        } else {
            //down scroll
            if ((document.documentElement.clientHeight - footerOffset.top) < -1 && sideBar.current.style.position === 'absolute') {
                sideBar.current.setAttribute('style', '');
                sideBar.current.style.position = 'fixed';
                sideBar.current.style.bottom = '0px';
                sideBar.current.style.width = '360px';
            } else if (sideBarOffset.top <= listContainer.top) {
                sideBar.current.setAttribute('style', '');
            }

        }
        this.lastScrollTop = st <= 0 ? 0 : st;

    };


    componentDidMount() {
        const html = document.querySelector('html');
        html.style.height = 'initial';
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }
}

const mapStateToProps = state => {
    return {
        app: state.app,
        category: state.category,
        user: state.user,
        job: state.job,
        company: state.company
    }
};


export default connect(mapStateToProps, {
    jobFilterInputChange,
    getAllJobs,
    generateStateUpdate,
    followCompany,
    openUserDetails
})(Home);